:host ::ng-deep {
  // 最外層任務
  tr[aria-expanded].e-row {
    td.e-rowcell {
      color: #053B50 !important;
    }
    font-weight: bolder;
  }
  // taskbar高度
  .e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div,
  .e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
    height: 12px !important;
    margin-top: 3px !important;
  }

  .e-task-label{
    font-size: 12px !important;
    line-height: 12px !important;
  }
  // baseline高度
  .e-baseline-bar{
    height: 6px !important;
  }
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: calc(100% - 100px / 2);
  max-width: 100%;
}

// region loading animation
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em
  }
  40% {
    box-shadow: 0 2.5em 0 0
  }
}

// endregion
