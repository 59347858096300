$breakpoints: (xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  sx: 1366px,
  x: 1440px,
  xl: 1600px,
  xxl: 1920px,
);


@mixin media-breakpoint-down($keyword) {

  $breakpoint-value: map-get($breakpoints, $keyword);

  @media (max-width: $breakpoint-value) {
    @content
  }
}
