::ng-deep {
  .p-dialog {
    // .p-dialog-header {
    //   @include media-breakpoint-down(md) {
    //     padding: 10px;
    //   }
    // }

    // .p-dialog-content {
    //   @include media-breakpoint-down(md) {
    //     padding: 0 10px 10px 10px;
    //   }
    // }

    .p-dialog-footer {

      .p-confirm-dialog-reject {
        // background-color: #64748B;
        background-color: #EF4444;
      }

      // .p-confirm-dialog-accept {
      //   background-color: #EF4444;
      // }

      @include media-breakpoint-down(md) {
        padding: 0 10px 15px 10px;
      }
    }
  }
}
