// @import '../../node_modules/@syncfusion/ej2-material-theme/styles/material.css';

// import custom

@import './custom';

/* You can add global styles to this file, and also import other style files */

html,
body {
  background: #FAFAFA;
}

body {
  margin: 0;
  font-family: "Noto Sans TC", "Roboto", "Helvetica Neue", sans-serif;

  * {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
}

.data-box {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 1rem;
}

.data-box:hover {
  background: #FCFCFC;
  cursor: pointer;
}
