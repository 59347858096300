:host ::ng-deep {
  .e-multi-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 42px;

    .e-input-group-icon {
      align-self: stretch;
    }

    .e-chips-close.e-close-hooker {
      margin-top: calc((42px - 24px) / 2);
      top: 0;
    }
  }
}
