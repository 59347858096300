@import './primeng/dialog';
@import './primeng/table';

:host ::ng-deep {
  .p-menubar {
    .p-submenu-list {
      z-index: 16;
    }
  }

  .p-calendar {
    width: 100%;
  }

  .p-inputtext, .p-multiselect, .p-inputnumber {
    width: 100%;
    border-radius: 6px;
  }

  .p-dropdown {
    width: 100%;
  }
}
